define("discourse/plugins/chat/discourse/components/chat-channel-status", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel", "discourse/plugins/chat/discourse/components/chat-channel-archive-status", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _discourseI18n, _chatChannel, _chatChannelArchiveStatus, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelStatus extends _component.default {
    LONG_FORMAT = "long";
    SHORT_FORMAT = "short";
    VALID_FORMATS = [this.SHORT_FORMAT, this.LONG_FORMAT];
    get format() {
      return this.VALID_FORMATS.includes(this.args.format) ? this.args.format : this.LONG_FORMAT;
    }
    get shouldRender() {
      return this.channelStatusIcon && this.args.channel.status !== _chatChannel.CHANNEL_STATUSES.open;
    }
    get channelStatusMessage() {
      if (this.format === this.LONG_FORMAT) {
        return this.#longStatusMessage(this.args.channel.status);
      } else {
        return this.#shortStatusMessage(this.args.channel.status);
      }
    }
    get channelStatusIcon() {
      return (0, _chatChannel.channelStatusIcon)(this.args.channel.status);
    }
    #shortStatusMessage(status1) {
      switch (status1) {
        case _chatChannel.CHANNEL_STATUSES.archived:
          return _discourseI18n.default.t("chat.channel_status.archived");
        case _chatChannel.CHANNEL_STATUSES.closed:
          return _discourseI18n.default.t("chat.channel_status.closed");
        case _chatChannel.CHANNEL_STATUSES.open:
          return _discourseI18n.default.t("chat.channel_status.open");
        case _chatChannel.CHANNEL_STATUSES.readOnly:
          return _discourseI18n.default.t("chat.channel_status.read_only");
      }
    }
    #longStatusMessage(status1) {
      switch (status1) {
        case _chatChannel.CHANNEL_STATUSES.archived:
          return _discourseI18n.default.t("chat.channel_status.archived_header");
        case _chatChannel.CHANNEL_STATUSES.closed:
          return _discourseI18n.default.t("chat.channel_status.closed_header");
        case _chatChannel.CHANNEL_STATUSES.open:
          return _discourseI18n.default.t("chat.channel_status.open_header");
        case _chatChannel.CHANNEL_STATUSES.readOnly:
          return _discourseI18n.default.t("chat.channel_status.read_only_header");
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="chat-channel-status">
            {{dIcon this.channelStatusIcon}}
            <span>{{this.channelStatusMessage}}</span>
            <ChatChannelArchiveStatus @channel={{@channel}} />
          </div>
        {{/if}}
      
    */
    {
      "id": "Mid2cmwP",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-status\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[30,0,[\"channelStatusIcon\"]]],null]],[1,\"\\n        \"],[10,1],[12],[1,[30,0,[\"channelStatusMessage\"]]],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-status.js",
      "scope": () => [_dIcon.default, _chatChannelArchiveStatus.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelStatus;
});