define("discourse/plugins/chat/discourse/components/chat/routes/channel-thread", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/plugins/chat/discourse/components/chat/thread/header", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _header, _chatThread, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesChannelThread extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "showfullTitle", [_tracking.tracked], function () {
      return false;
    }))();
    #showfullTitle = (() => (dt7948.i(this, "showfullTitle"), void 0))();
    setFullTitle(value1) {
      this.showfullTitle = value1;
    }
    static #_2 = (() => dt7948.n(this.prototype, "setFullTitle", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --channel-thread">
          {{#each (array @thread) as |thread|}}
            <ThreadHeader
              @thread={{thread}}
              @showFullTitle={{this.showfullTitle}}
            />
    
            <Thread
              @thread={{thread}}
              @targetMessageId={{@targetMessageId}}
              @includeHeader={{true}}
              @setFullTitle={{this.setFullTitle}}
            />
          {{/each}}
        </div>
      
    */
    {
      "id": "19/Jbc8K",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --channel-thread\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0],[[30,1]],null]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@thread\",\"@showFullTitle\"],[[30,2],[30,0,[\"showfullTitle\"]]]],null],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@thread\",\"@targetMessageId\",\"@includeHeader\",\"@setFullTitle\"],[[30,2],[30,3],true,[30,0,[\"setFullTitle\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@thread\",\"thread\",\"@targetMessageId\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-thread.js",
      "scope": () => [_helper.array, _header.default, _chatThread.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesChannelThread;
});