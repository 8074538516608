define("discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "truth-helpers", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _truthHelpers, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesChannelInfoNav extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showTabs() {
      return this.site.desktopView && this.args.channel.isOpen;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --channel-info-nav">
          {{#if this.showTabs}}
            <nav class="c-channel-info__nav">
              <ul class="nav nav-pills">
                <li>
                  <LinkTo
                    @route="chat.channel.info.settings"
                    @models={{@channel.routeModels}}
                    class={{if (eq @tab "settings") "active"}}
                    @replace={{true}}
                  >
                    {{i18n "chat.channel_info.tabs.settings"}}
                  </LinkTo>
                </li>
                <li>
                  <LinkTo
                    @route="chat.channel.info.members"
                    @models={{@channel.routeModels}}
                    class={{if (eq @tab "members") "active"}}
                    @replace={{true}}
                  >
                    {{i18n "chat.channel_info.tabs.members"}}
                    {{#if @channel.isCategoryChannel}}
                      <span
                        class="c-channel-info__member-count"
                      >({{@channel.membershipsCount}})</span>
                    {{/if}}
                  </LinkTo>
                </li>
              </ul>
            </nav>
          {{/if}}
        </div>
      
    */
    {
      "id": "Or7vBbQ8",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --channel-info-nav\"],[12],[1,\"\\n\"],[41,[30,0,[\"showTabs\"]],[[[1,\"        \"],[10,\"nav\"],[14,0,\"c-channel-info__nav\"],[12],[1,\"\\n          \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[32,0],[[16,0,[52,[28,[32,1],[[30,1],\"settings\"],null],\"active\"]]],[[\"@route\",\"@models\",\"@replace\"],[\"chat.channel.info.settings\",[30,2,[\"routeModels\"]],true]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[32,2],[\"chat.channel_info.tabs.settings\"],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[32,0],[[16,0,[52,[28,[32,1],[[30,1],\"members\"],null],\"active\"]]],[[\"@route\",\"@models\",\"@replace\"],[\"chat.channel.info.members\",[30,2,[\"routeModels\"]],true]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[32,2],[\"chat.channel_info.tabs.members\"],null]],[1,\"\\n\"],[41,[30,2,[\"isCategoryChannel\"]],[[[1,\"                  \"],[10,1],[14,0,\"c-channel-info__member-count\"],[12],[1,\"(\"],[1,[30,2,[\"membershipsCount\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@tab\",\"@channel\"],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav.js",
      "scope": () => [_routing.LinkTo, _truthHelpers.eq, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesChannelInfoNav;
});