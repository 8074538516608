define("discourse/plugins/chat/discourse/components/chat/navbar/browse-channels-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarBrowseChannelsButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    browseChannelsLabel = (() => _discourseI18n.default.t("chat.channels_list_popup.browse"))();
    get showBrowseChannelsButton() {
      return this.router.currentRoute.name === "chat.channels";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showBrowseChannelsButton}}
          <LinkTo
            @route="chat.browse"
            class="btn no-text btn-flat c-navbar__browse-button"
            title={{this.browseChannelsLabel}}
          >
            {{icon "pencil-alt"}}
          </LinkTo>
        {{/if}}
      
    */
    {
      "id": "rRswkpDU",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showBrowseChannelsButton\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn no-text btn-flat c-navbar__browse-button\"],[16,\"title\",[30,0,[\"browseChannelsLabel\"]]]],[[\"@route\"],[\"chat.browse\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"pencil-alt\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/browse-channels-button.js",
      "scope": () => [_routing.LinkTo, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarBrowseChannelsButton;
});