define("discourse/plugins/chat/discourse/components/chat/modal/delete-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/helpers/with-event-value", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-common/lib/later", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _service, _template2, _utils, _dButton, _dModal, _withEventValue, _ajaxError, _i18n, _later, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalDeleteChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "channelNameConfirmation", [_tracking.tracked]))();
    #channelNameConfirmation = (() => (dt7948.i(this, "channelNameConfirmation"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "deleting", [_tracking.tracked], function () {
      return false;
    }))();
    #deleting = (() => (dt7948.i(this, "deleting"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "confirmed", [_tracking.tracked], function () {
      return false;
    }))();
    #confirmed = (() => (dt7948.i(this, "confirmed"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "flashType", [_tracking.tracked]))();
    #flashType = (() => (dt7948.i(this, "flashType"), void 0))();
    get channel() {
      return this.args.model.channel;
    }
    get buttonDisabled() {
      if (this.deleting || this.confirmed) {
        return true;
      }
      if ((0, _utils.isEmpty)(this.channelNameConfirmation) || this.channelNameConfirmation.toLowerCase() !== this.channel.title.toLowerCase()) {
        return true;
      }
      return false;
    }
    get instructionsText() {
      return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.channel_delete.instructions", {
        name: this.channel.escapedTitle
      }));
    }
    deleteChannel() {
      this.deleting = true;
      return this.chatApi.destroyChannel(this.channel.id, this.channelNameConfirmation).then(() => {
        this.confirmed = true;
        this.flash = _discourseI18n.default.t("chat.channel_delete.process_started");
        this.flashType = "success";
        (0, _later.default)(() => {
          this.args.closeModal();
          this.router.transitionTo("chat");
        }, 3000);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.deleting = false);
    }
    static #_8 = (() => dt7948.n(this.prototype, "deleteChannel", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-delete-channel"
          @inline={{@inline}}
          @title={{i18n "chat.channel_delete.title"}}
          @flash={{this.flash}}
          @flashType={{this.flashType}}
        >
          <:body>
            <p class="chat-modal-delete-channel__instructions">
              {{this.instructionsText}}
            </p>
            <input
              {{on
                "input"
                (withEventValue (fn (mut this.channelNameConfirmation)))
              }}
              id="channel-delete-confirm-name"
              placeholder={{i18n "chat.channel_delete.confirm_channel_name"}}
              autocorrect="off"
              autocapitalize="off"
              type="text"
            />
          </:body>
          <:footer>
            <DButton
              @disabled={{this.buttonDisabled}}
              @action={{this.deleteChannel}}
              @label="chat.channel_delete.confirm"
              id="chat-confirm-delete-channel"
              class="btn-danger"
            />
            <DButton @label="cancel" @action={{@closeModal}} class="btn-flat" />
          </:footer>
        </DModal>
      
    */
    {
      "id": "7XwvAJ2V",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-delete-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\",\"@flashType\"],[[30,1],[30,2],[28,[32,1],[\"chat.channel_delete.title\"],null],[30,0,[\"flash\"]],[30,0,[\"flashType\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-delete-channel__instructions\"],[12],[1,\"\\n          \"],[1,[30,0,[\"instructionsText\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[11,\"input\"],[24,1,\"channel-delete-confirm-name\"],[16,\"placeholder\",[28,[32,1],[\"chat.channel_delete.confirm_channel_name\"],null]],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[24,4,\"text\"],[4,[32,2],[\"input\",[28,[32,3],[[28,[32,4],[[28,[31,0],[[30,0,[\"channelNameConfirmation\"]]],null]],null]],null]],null],[12],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,5],[[24,1,\"chat-confirm-delete-channel\"],[24,0,\"btn-danger\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"deleteChannel\"]],\"chat.channel_delete.confirm\"]],null],[1,\"\\n        \"],[8,[32,5],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[\"mut\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/delete-channel.js",
      "scope": () => [_dModal.default, _i18n.default, _modifier.on, _withEventValue.default, _helper.fn, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalDeleteChannel;
});